//Dependencies
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
//Components
import { BreadcrumbContext } from '../Layout/Layout';
import TableConfig from './passholdersTableConfig.json';
import Table from '../../atoms/Table/Table';
import MultiSelectDropdown from '../../atoms/MultiSelectDropdown';
//API
import { getPassTypes } from '../dataAPI';
import { getAllPassesOfMultipleLocationsTemplatesV2 } from './passCreatorAPI';
import { getLocationsData, getCustomerLocationsData } from './../Locations/locationsAPI';
import { getSitesData } from './../Sites/sitesAPI';
//Utilities
import { parsePassholdersData } from '../../utilities/pass';
import { getUserData } from '../../utilities/app';
//slices
import { customerSelected } from '../Customers/customerSlice';
import { passTemplatesAdded } from '../../appSlices';
import { passholdersAdded, passholderselected } from './passholdersSlice';
import { locationsAdded } from '../Locations/locationsSlice';
import { sitesAdded } from '../Sites/sitesSlice';

import classNames from 'classnames';

function Passholder() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, visibleColumns, exportableColumns } = TableConfig;
  const passholders = useSelector(state => state.passholder.passholders);
  const [originalGridData, setOriginalPassholdersData] = useState(passholders);
  const [gridData, setPassholdersData] = useState(passholders);
  const [selectedFilters, setSelectedFilters] = useState({});
  const passTemplates = useSelector(state => {
    return state.appData.passTemplates
  });
  const [loadingData, setLoadingData] = useState(false);
  const [passTemplatesData, setPassTemplatesData] = useState(passTemplates);
  const sites = useSelector(state => state.site.sites);
  const locations = useSelector(state => state.location.locations);
  const { userType, customerID, isCustomerUser } = getUserData();
  
  useEffect(() => {
    !userType && navigate('/signin');
  }, [userType]);

  const getPassholdersData = async () => {
    try {
      setLoadingData(true);
      getAllPassesOfMultipleLocationsTemplatesV2(
        locations,
        (data) => {
          dispatch(passholdersAdded(data));
          setLoadingData(false);
        }
      );
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Customers",
        "path": "/customers"
      },
      {
        "linkText": "All Passholders",
        "path": "/passholders"
      }
    ]);
    (!passTemplates || !passTemplates.length) && getPassTypes(data =>  dispatch(passTemplatesAdded(data)));
    return () => {}
  }, []);

  useEffect(() => {
    const data = parsePassholdersData(passholders, sites, locations);
    setOriginalPassholdersData(data);
    setPassholdersData(data);
  }, [passholders]);

  useEffect(() => {
    setPassTemplatesData(passTemplates);
  }, [passTemplates]);

  useEffect(() => {
    (locations && locations.length) && getPassholdersData();
  }, [locations]);

  const refetchLocationsData = () => isCustomerUser ? getCustomerLocationsData(
      customerID,
      (locations, customerData) => {
        dispatch(customerSelected(customerData))
        dispatch(locationsAdded(locations));
      }
  ) : getLocationsData(data => dispatch(locationsAdded(data)));

  useEffect(() => {
      (!sites || !sites.length ) && getSitesData(data => {
        dispatch(sitesAdded(data));
        refetchLocationsData();
      }, customerID);
  }, []);

  const viewEditPassholder = (opType, row) => {
    localStorage.setItem('selectedPassholder', JSON.stringify(row));
    dispatch(passholderselected(row));
    navigate(`/passholdersview`);
  }

  const filterPassholderData = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      identifier: '',
      passTemplateGuid: '',
      locationPasS_TEMPLATE_ID: '',
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setPassholdersData(originalGridData);

    const searchData = [];
    originalGridData.forEach((row) => {
      selectedValue?.includes(row[filterByAttr]?.toString()) && searchData.push(row);
    });
    setPassholdersData(searchData);
  };

  const filterPassholderDataByLocation = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      identifier: '',
      passTemplateGuid: '',
      locationPasS_TEMPLATE_ID: '',
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setPassholdersData(originalGridData);

    const locationPassTemplateID = selectedValue.split('--')[1];

    const searchData = [];
    originalGridData.forEach((row) => {
      locationPassTemplateID?.includes(row['passTemplateGuid']?.toString()) && searchData.push(row);
    });
    setPassholdersData(searchData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="passholders-grid-row-actions">
        <button onClick={() => viewEditPassholder('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
      </div>
    );
    
    if(key === 'status') {
      return (
        <div className={classNames({"passholders-grid-status": true, "passholders-grid-status-active": value === 'Active'})}>{value}</div>
      )
    }

    if(key === 'sites') return row.sites.length || 0;

    return value;
  }

  const clearSelectedFilter = () => {
    setSelectedFilters({
      identifier: '',
      passTemplateGuid: '',
      locationPasS_TEMPLATE_ID: ''
    });
    setPassholdersData([...originalGridData]);
  }

  const filterPassholderDataOnTemplatesSelection = (value) => {
    //'identifier'
    filterPassholderData('passHolder', value);
    console.log(value);
  }

  const renderTableFilters = () => (
    <div className="passholders-filter">
      <label>Filter by</label>
      <MultiSelectDropdown
        name="identifier"
        preSelectedOptions={ selectedFilters.identifier || ''}
        dropdownData={originalGridData}
        optionID="passHolder"
        optionNAME="passHolder"
        placeholder="Select Passholder IDs"
        txtSelected="Selected"
        onChange={filterPassholderDataOnTemplatesSelection?.bind(this)}
      />
    
      <select name="siteFilter" value={selectedFilters.passTemplateGuid} id="state" onChange={(e) => filterPassholderData('passTemplateGuid', e.target.value)}>
        <option value="">Pass Type</option>
        {
          passTemplatesData.map(({pasS_NEW_ID, pasS_NAME}, index) => (
            <option key={`pass${index}`} value={pasS_NEW_ID}>{pasS_NAME}</option>
          ))
        }
      </select>

      <select name="siteFilter" value={selectedFilters.identifier} id="state" onChange={(e) => filterPassholderData('status', e.target.value)}>
        <option value="">Status</option>
        <option value="Active">{'Active'}</option>
        <option value="Inactive">{'In Active'}</option>
      </select>

      <select name="siteFilter" value={selectedFilters.identifier} id="state" onChange={(e) => filterPassholderData('identifier', e.target.value)}>
        <option value="">Site Name</option>
        {
          sites.map(data => (
            <option value={data.sitE_ID}>{data.sitE_NAME}</option>
          ))
        }
      </select>

      <select name="siteFilter" value={selectedFilters.locationPasS_TEMPLATE_ID} id="state" onChange={(e) => filterPassholderDataByLocation('locationPasS_TEMPLATE_ID', e.target.value)}>
        <option value="">Locations</option>
        {
          locations.map(data => (
            <option value={`${data.locatioN_ID}--${data.pasS_TEMPLATE_ID}`}>{data.locatioN_NAME}</option>
          ))
        }
      </select>
      
      <button className="passholders-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  );

  /**
   <select name="siteFilter" value={selectedFilters.identifier} id="state" onChange={(e) => filterPassholderData('identifier', e.target.value)}>
        <option value="">Customer ID</option>
        {
          originalGridData.map(pass => (
            <option value={pass.identifier}>{pass.customeR_ID}</option>
          ))
        }
      </select>
   */

  return (
    <div className="passholders">
      <Table
        renderTableFilters={renderTableFilters}
        TableTitle={`All Passholders`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'identifier'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
        loadingData={loadingData}
      />
    </div>
  );
};

export default Passholder;