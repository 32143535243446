import axios from "axios";
import { bookingsBaseURL, getAllBookingsURLV2, getAllBookingsURLV3 } from "../../constants/apiUrl";

export const getAllBookingsV2 = async (startDate, endDate, listType, callbackFn) => {
  try {
    const response = await axios.request({
      baseURL: bookingsBaseURL,
      url: `${getAllBookingsURLV2}?periodFrom=${startDate}&periodTo=${endDate}&listType=${listType}`, //?periodFrom={{dateTimeFrom}}&periodTo={{dateTimeTo}}&listType={{listType}}&dataOffset={{dataStart}}&dataLimit={{dataSize}}
      method: 'get',
    });
    const bookings = [
      ...response?.data?.data
    ];
    callbackFn(bookings);
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};

export const getAllBookingsV3 = async (startDate, endDate, listType, dataOffset, dataLimit, callbackFn) => {
  try {
    const response = await axios.request({
      baseURL: bookingsBaseURL,
      url: `${getAllBookingsURLV3}?periodFrom=${startDate}&periodTo=${endDate}&listType=${listType}&dataOffset=${dataOffset}&dataLimit=${dataLimit}`, //?periodFrom={{dateTimeFrom}}&periodTo={{dateTimeTo}}&listType={{listType}}&dataOffset={{dataStart}}&dataLimit={{dataSize}}
      method: 'get',
    });
    const bookings = [
      ...response?.data?.data
    ];
    callbackFn(bookings);
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};
