import { createSlice } from '@reduxjs/toolkit';

export const parseCustomerData = data => data?.map(data => ({
  ...data,
  'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
  'status': data.activE_IND ? 'Approved': 'Pending',
  'sitesCount': data.sites.length || 0
}));

const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: [],
    updateCustomer: 0,
    selectedCustomer: {}
  },
  reducers: {
    customersAdded(state, action) {
      state.customers = [...action.payload];
    },
    customersUpdated(state, action) {
      state.updateCustomer = Math.floor(Math.random()*10000);
    },
    customerSelected(state, action) {
      state.selectedCustomer = {...action.payload}
    },
  },
})

export const { customersAdded, customersUpdated, customerSelected } = customersSlice.actions;
export default customersSlice.reducer