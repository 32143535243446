import { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../Layout/Layout";
import Table from "../../atoms/Table";
import TableConfig from './bookingDetailsTableConfig.json';

function BookingDetails() {
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const selectedBooking = JSON.parse(localStorage.getItem('selectedBooking'));
    const { header, visibleColumns, exportableColumns } = TableConfig;
    const [originalGridData, setOriginalGridData] = useState([]);
    const [gridData, setGridData] = useState([]);

    const prepareTableData = () => {
        const {
            ...bookingData
        } = selectedBooking;
        const data = Object.keys(bookingData).map((key) => ({
            data_type: key,
            data_value: bookingData[key] && typeof bookingData[key] === 'object' && !Array.isArray(bookingData[key]) ? [bookingData[key]] : bookingData[key]
        }));
        setOriginalGridData(data);
        setGridData(data);
    }

    useEffect(() => {
        setCurrentBreadcrumb([
            {
                "linkText": "Customers",
                "path": "/customers"
            },
            {
                "linkText": "All Bookings",
                "path": "/bookings"
            },
          {
            "linkText": "Booking View",
            "path": "/bookingview"
          },
          {
            "linkText": selectedBooking?.name,
            "path": "/bookingview"
          }
        ]);
        prepareTableData();
        return () => {}
    }, []);

    const generateDataColumn = (key, value, row) => {
        if(typeof value === 'object' && Array.isArray(value)) {
            return (
              <div className='keyValues-container'>
                {
                  value?.map(row => Object.keys(row).map(key => (
                    <div>
                      {key}: {JSON.stringify(row[key])}
                    </div>
                  )))
                }
              </div>
            ) 
        }
        return value;
    }

    return (
        <div className="booking-details">
            <Table
                TableTitle={`Booking Details`}
                header={header}
                visibleColumns={visibleColumns}
                originalData={originalGridData}
                data={gridData}
                rowId={'data_type'}
                exportableColumns={exportableColumns}
                generateDataColumn={generateDataColumn}
            />
        </div>
    )
}

export default BookingDetails;