export const baseURL = 'https://api-vrfid-id.azurewebsites.net/api';
export const sitesURL = '/site/sites';
export const customerSitesURL = '/site/siteByCustId';
export const locationsURL = '/location/locations';
export const customerLocationsURL = '/site/siteByLocationId'
export const getLocationURL = '/location/locationById';
export const addLocationURL = '/location/addLocation';
export const deleteLocationURL = '/location/deleteLocation';
export const getCustomerDropdownDataURL = '/site/siteCustomer';
export const countriesURL = '/countries';
export const getAllCustomersURL = '/GetAllCustomers';
export const getCustomersURL = '/GetCustomerById';
export const verifyOtpURL = '/VerifyOTP';
export const addCustomerURL = '/addCustomers';
export const updateCustomerURL = '/updateCustomer';
export const customersURL = '/customers';
export const addUserURL = '/AddUser';
export const changePasswordURL = '/changepassword';
export const getPassTypesURL = '/pass/allpasstype';
export const addPassTypesURL = '/pass/addPasstype';
export const getAllScheduledReports = '/getAllScheduledReports';
export const addReportURL = '/addreport';

// pass creator APIs
export const passCreatorAPIBaseURL = 'https://anithswork.com';
export const getPassTemplatesURL = '/Passcreator/GetListOfTemplates';
export const getPassesOfMultipleTemplatesURLv2 = '/Passcreator/GetPassesOfMultipleTemplatesV2';
export const getAllPassesOfTemplateURL = '/Passcreator/GetAllPassesOfTemplate';

// door APIs
export const createDoorURL = '/Door/CreateDoor';

// SMS gateway URL
export const  smsBaseURL = "https://anithswork.com/Twilio";
export const sendSMSURL = "/SendSMSV2";

//Bookings URL
export const bookingsBaseURL = 'https://anithswork.com';
export const getAllBookingsURLV2 = '/NewBook/GetBookingsListV2'
export const getAllBookingsURLV3 = '/NewBook/GetBookingsListV3'
