import { createSlice } from '@reduxjs/toolkit'
import bookingData from './bookingsJson.json'

export const getFormattedDate = date => {
  date = new Date(date);
  return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  //return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
}
let currentDate = new Date();
let lastMonthDate = new Date();
lastMonthDate = new Date(lastMonthDate.setDate(lastMonthDate.getDate() - 30));

const bookingsSlice = createSlice({
  name: 'booking',
  initialState: {
    bookings: [],
    classifiedBookings: [],
    updateBooking: 1,
    selectedBooking: {},
    selectedFilters: {
      startDate: lastMonthDate,
      endDate: currentDate,
    }
  },
  reducers: {
    bookingsAdded(state, action) {
      state.bookings = [...action.payload];
    },
    bookingsUpdated(state, action) {
      state.updateBooking = Math.floor(Math.random()*10000);
    },
    bookingSelected(state, action) {
      state.selectedBooking = {...action.payload}
    },
    classifiedBookingsAdded(state, action) {
      state.classifiedBookings = {...action.payload};
    },
    filtersAdded(state, action) {
      state.selectedFilters = {...action.payload};
    },
  },
})

export const { bookingsAdded, bookingsUpdated, bookingSelected, classifiedBookingsAdded, filtersAdded } = bookingsSlice.actions
export default bookingsSlice.reducer