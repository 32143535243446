import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../utilities/app';

function ProfileDropdown() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const { userType, isCustomerUser, customerID, name, logO_IMAGE } = getUserData();
  React.useEffect(() => {
    !userType && navigate('/signin');
  }, [userType]);

  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    sessionStorage.clear();
    localStorage.clear();
    navigate("/signin");
  }

  const handleMenuClick = (path) => {
    localStorage.setItem('selectedCustomerId', customerID);
    handleClose();
    navigate(path);
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'prypeofile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div className="profile">
          <div className="profile-pic">
            <img src={logO_IMAGE ? `data:image/jpeg;base64,${logO_IMAGE}` : "/assets/customers/default-pic.png"}/>
          </div>
          <div className="profile-details">
            <div className="profile-role">{userType === 'admin' ? 'Super Admin' : userType}</div>
            <div className="profile-name">{name ? name : 'Mrs Chas'}</div>
          </div>
          <i className="fa fa-caret-down profile-icon" style={{fontSize: '20px'}}></i>
        </div>
      </Button>
      <Menu
        id="profile-menu"
        className='profile-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        { isCustomerUser ? <MenuItem onClick={() => handleMenuClick('/profile')}>Profile</MenuItem> : null }
        <MenuItem onClick={() => handleMenuClick('/changepassword')}>Change Password</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

export default ProfileDropdown;
