import { useEffect, useState } from "react";
import classNames from 'classnames';
import Tooltip from "../Tooltip";
import { toast } from 'react-toastify';
import { exportToPDF, exportToCSVExls } from './exportUtilities';

function Table({
  renderTableFilters,
  TableTitle,
  ReportTitle,
  header,
  visibleColumns,
  originalData,
  data,
  viewEditRow,
  deleteRow,
  rowId,
  exportableColumns,
  handleAddAction,
  loadingData,
  ...props
}) {
  const [columnHeaders, setColumnHeaders] = useState(header);
  const [gridData, setGridData] = useState(data);
  const [searchKey, setSearchKey] = useState(null);
  const [allRowSelected, setAllRowSelected] = useState(false);
  const [exportFileType, setExportFileType] = useState('csv');

  useEffect(() => {
    setGridData(data);
  }, [data]);

  const handleTableSearch = () => {
    if(!searchKey) return setGridData(originalData);

    const searchData = [];
    originalData.forEach((row) => {
      Object.values(row).toString().toLowerCase().includes(searchKey.toLowerCase()) && searchData.push(row);
    });
    setGridData(searchData);
  }

  const sortTable = (columnKey, colIndex) => {
    const columns = [...columnHeaders];
    let sortedData = [];
    if(columns[colIndex]?.ascSort) {
      columns[colIndex].ascSort = false;
      columns[colIndex].descSort = true;
      sortedData = gridData.slice().sort((a,b) => b[columnKey]?.toString()?.localeCompare(a[columnKey]));
    } else {
      columns[colIndex].ascSort = true;
      columns[colIndex].descSort = false;
      sortedData = gridData.slice().sort((a,b) => {
        return a[columnKey]?.toString()?.localeCompare(b[columnKey])
    });
    }
    setColumnHeaders([
      ...columns,
    ]);
    setGridData(sortedData);
  }

  const generateDataColumn = (key, value, row) => {
    if(props.generateDataColumn) return props.generateDataColumn(key, value, row);
  
    if(key === "actions") return (
      <div className="vrfid-datatable-grid-row-actions">
        <button onClick={() => viewEditRow('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditRow('edit', row)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteRow(row)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );
    
    if(key === 'activE_IND') {
      if(value) return (
        <div className="vrfid-datatable-grid-status passes-grid-status-active">{'Active'}</div>
      )

      return (
        <div className="vrfid-datatable-grid-status">{'Inactive'}</div>
      )
    }

    return value;
  }

  const generateRows = () => gridData.map((row, index) => (
    <tr key={`tr${index}`}>
      <td>
        <input type="checkbox" checked={row.isSelected} name="rowSelect" onChange={(e) => handleRowSelect(e, row[rowId])}/>
      </td>
      {
        visibleColumns.map((key, colIndex) => (
          <td key={`td${index}${colIndex}`}> { generateDataColumn(key, row[key], row) } </td>
        ))
      }
    </tr>
  ));

  const exportData = () => {
    const exportData = [];
    const pdfExportData = [];
    const headers = [];
    columnHeaders.forEach(({columnName, isExportHidden}) => !isExportHidden && headers.push(columnName));
    exportData.push(headers.join(','));
    gridData.forEach(row => {
      const rowData = [];
      if(row.isSelected) { 
        exportableColumns.forEach(key => {
          if(exportFileType !== 'pdf' && key.toLowerCase().includes('phone')) {
            rowData.push(row[key]?.toString() + '`')
          } else {
            rowData.push( typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]?.toString())
          }
      });
        exportData.push(rowData.join(','))
        pdfExportData.push(rowData);
      }
    });
    if(exportData.length <= 1) {
      toast('Please select the table rows first and try again to download.')
      return;
    }

    if(exportFileType === 'pdf') {
      exportToPDF(TableTitle, headers, pdfExportData, ReportTitle);
    } else {
      exportToCSVExls(TableTitle, exportData, exportFileType, ReportTitle);
    }
  }

  const renderTableActions = () => (
    <div className="vrfid-datatable-grid-heading">
      <div>
        {TableTitle}
      </div>
      <div className="vrfid-datatable-grid-actions">
        <label>File Type</label>
        <select className="export-dropdown" name="exportFileType" value={exportFileType} id="exportFileType" onChange={(e) => setExportFileType(e.target.value)}>
          <option value="csv">CSV</option>
          <option value="xlsx">XLS</option>
          <option value="pdf">PDF</option>
        </select>
        <button className="passes-filter-clear export-btn" onClick={exportData}>
          <img src="/assets/customers/export.svg"/>
          Export Selected Rows
        </button>
        <input name="tableSearchKey" placeholder="Type in any table data text to search" onChange={(e) => setSearchKey(e.target.value)}/>
        <button className="vrfid-datatable-grid-actions-search" onClick={handleTableSearch}>
          <img src="/assets/customers/search.png"/>
        </button>
        {handleAddAction ? (
          <button className="customers-grid-actions-add" onClick={handleAddAction}>
            <img src="/assets/customers/add.png"/>
          </button>
        ) : null}
      </div>
    </div>
  );

  const handleRowSelect = (e, selectedRowId) => {
    const isSelected = e.target.checked;
    if(selectedRowId) {
      const data = gridData.map((row) => {
        if(row[rowId] === selectedRowId) {
          return { ...row, isSelected}
        }
        return row;
      });
      setGridData(data);
    } else {
      const data = gridData.map((row) => ({ ...row, isSelected}));
      setAllRowSelected(isSelected);
      setGridData(data);
    }
  };

  const generateColumnHeaders = () => (
    <tr>
      <th>
        <input checked={allRowSelected} type="checkbox" name="allSelect" onChange={(e) => handleRowSelect(e)}/>
      </th>
      {
        columnHeaders.map(({columnName, columnKey, isSortable, descSort, ascSort}, colIndex) => (
        <th key={`th${colIndex}`}>
          {
            isSortable ? (
              <div key={`col${colIndex}`} className='table-column' onClick={() => sortTable(columnKey, colIndex)}>
                {columnName}
                <span>
                  <i className={classNames({"fa fa-sort-asc": true, "active": ascSort})} style={{height:'5px'}}></i>
                  <i className={classNames({"fa fa-sort-desc": true, "active": descSort})}></i>
                </span>
                <Tooltip tooltipText="Sorting by Alphabetical order">
                  <i className="fa fa-info-circle"></i>
                </Tooltip>
              </div>
            ) :
            <>{columnName}</>
          }
        </th>))
      }
    </tr>
  )

  return (
    <div className="vrfid-datatable">
      { renderTableFilters ? renderTableFilters() : null}

      { renderTableActions() }

      <div className="vrfid-datatable-grid">
        <table frame="hsides" rules="rows" className="vrfid-datatable-grid-datatable">
          <thead>
            { generateColumnHeaders() }
          </thead>
          <tbody>
            {
              loadingData ? (
                <tr>
                  <td colSpan={columnHeaders.length} className="no-data-cell">
                    <div className="vrfid-datatable-loading"></div>
                  </td>
                </tr>
              ) : gridData.length ? generateRows() : (
                <tr>
                  <td colSpan={columnHeaders.length} className="no-data-cell">No data records found.</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table;