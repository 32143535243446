import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import { getFormattedDate } from '../../components/Bookings/bookingsSlice';

export const exportToPDF = (TableTitle, headers, exportData, ReportTitle) => {
  try {
    const doc = jsPDF('l', 'mm','a4');// new jsPDF();

    //Html way
    //autoTable(doc, { html: '#table-id' })
    // Sometimes you might have to call the default function on the export (for example in Deno)
    //autoTable.default(doc, { html: '#my-table' })

    // Javascript way:

    var header = function (data) {
      doc.setFontSize(18);
      //doc.text(headerText, 15, 18, { baseline: 'top' });
      doc.setTextColor('#159F15')
      //doc.setTextColor(40);
      //doc.setFontStyle('normal');
      doc.addImage('/assets/vrfid-logo.png', 'png', 15, 5, 35, 10);
      //doc.addImage(image base64_source, 'image format', logo_sizes.centered_x, _y, logo_sizes.w, logo_sizes.h);
      //Image must be Base64 encoded
    };
    autoTable(doc, {
      head: [[...headers]],
      body: [
        ...exportData
      ],
      headStyles :{fillColor : '#159F15'},
      styles: { halign: "top" },
      startY: 32,
      margin: {top: 20},
      beforePageContent: header,
    });

    //doc.setFontSize(12);
    // Header
    //doc.addImage('/assets/vrfid-logo.png', 'png', 15, 5, 35, 10);
    
    const pageCount = doc.internal.getNumberOfPages();
    for(let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      if(i==1) {
        const headerText = `${ReportTitle || TableTitle}`;
        doc.text(headerText, 20, 20, { baseline: 'top' });
        doc.text(`Report created on ${getFormattedDate(new Date())}`, 200, 30, { baseline: 'left' });
      }
      // Footer
      doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
    }

    doc.save(`${TableTitle}.pdf`);
    toast('Data exported successfully.')
  }
  catch(e) {
    toast('Something went wrong, please try again later.');
  }
};

export const exportToCSVExls = (TableTitle, exportData, exportFileType) => {
  try {
    exportData = exportData.join('\n');
    const CSVFile = new Blob([exportData], { type: `text/${exportFileType}` });
    let tempExportFileLink = document.createElement('a');
    tempExportFileLink.download = `${TableTitle}.${exportFileType}`;
    let url = window.URL.createObjectURL(CSVFile);
    tempExportFileLink.href = url;
    tempExportFileLink.style.display = "none";
    document.body.appendChild(tempExportFileLink);
    tempExportFileLink.click();
    document.body.removeChild(tempExportFileLink);
    toast('Data exported successfully.')
  }
  catch(e) {
    toast('Something went wrong, please try again later.');
  }
}